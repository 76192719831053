import React, { useRef, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Puff } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import SuccessPage from '../Success';

const StepFour = ({ setStep, step, clientDetails, setFormData, formData, ownerInfo }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { clientkey, merchant_id } = useParams();
  const [captchaValue, setCaptchaValue] = useState('');
  const [registrationCompleted, setRegistrationCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const captchaReference = useRef(null)
  const initialValues = {
    account_method: ownerInfo?.account_method ? ownerInfo?.account_method : "",
    routing_number: ownerInfo?.routing_number ? ownerInfo?.routing_number : "",
    account_number: ownerInfo?.account_number ? ownerInfo?.account_number : "",
    bank_name: ownerInfo?.bank_name ? ownerInfo?.bank_name : "",
   fax_four:''
  };

  const validationSchema = Yup.object({
    account_method: Yup.string().required('Account type is required'),
    account_number: Yup.string().matches(/^\d{4,17}$/, "Should be between 4 and 17 digits and contain numbers only").required('Account number is required'),
    routing_number: Yup.number().typeError('Routing number must be a number').required('Routing number is required'),
    bank_name: Yup.string().typeError("Bank name must be characters").required('Bank name is required'),
    fax_four:Yup.string()
  });

  // handle form submit
  const stepForthHandler = async (values) => {
    try {
      let data = {
        ...values,
        merchant_id,
        step: 4,
      }
      let saveStepFourToLocalStorage = JSON.stringify({ ...ownerInfo, ...values })
      localStorage.setItem('client-details', saveStepFourToLocalStorage);

      setLoading(true);
      if (merchant_id) {

        const response = await axios.post(
          `${apiUrl}/merchant/onboarding/${clientkey}`, data);
        if (response?.data?.result) {
          toast.success(response?.data?.message);
          setStep(step + 1);
        }
        else {
          toast.error(response?.data?.message);
        }
      }
      else {
        setFormData((pre) => ({ ...pre, ...values }))
        setStep(step + 1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    }
  }

  const handleSubmit = async (values) => {
    // e.preventDefault();
    if (!isCheckboxChecked) {
      return toast.error("Terms and conditions required");
    }
    if (!captchaValue) {
      return toast.error("Captcha is required");
    }
    try {
 
      
      let data = {
        // "file": base64Images,
        ...values,
        merchant_id,
        step: 4,
      }

      let dataAfterclean = formData?.ownership_detail?.reduce((acc, item, ind) => {
        acc[`OwnerInfo${ind > 0 ? ind + 1 : ""}`] = {
          ...item,

        };
        return acc;
      }, {});
      let deletedata = { ...formData }
      delete deletedata.ownership_detail
      delete deletedata.bussiness_to_business
      delete deletedata.bussiness_to_consumer
      delete deletedata.fax_one
      delete deletedata.fax_two
      delete deletedata.fax_three
      
      // delete deletedata.dba
      let finalData = { ...deletedata, ...dataAfterclean, ...values }
    
      //  return
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/merchant/onboarding/${clientkey}`, merchant_id ? data : finalData, {
        headers: {
          'Content-Type': 'application/json',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          // setUploadProgress(percentCompleted);
        }
      });
      if (response?.data?.result) {
        toast.success(response?.data?.message);
        setRegistrationCompleted(true);

      }
      else {
        toast.error(response?.data?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    }
  }

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  const handleCheckbox = (e) => {
    setIsCheckboxChecked(e.target.checked)
  }
  return (
    <div className="">
      <div className='client-logo'>
        <img src={clientDetails?.logo} alt='logo' />
      </div>
      {registrationCompleted ? <SuccessPage setStep={setStep} /> :
        <div className="container all-steps">
          <div className="heading-container">
            <div className="heading-text">
              <h2>Banking information</h2>
            </div>
            <div className="heading-ico">
              <img src="/banking-information.png" alt="Owner Info" />
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              // check bot
              if (values?.fax_four !== "") { return false; }

              // stepForthHandler(values)
              handleSubmit(values);
              // setStep(step + 1);
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>


                <div className='row'>
                  <div className='input-coll'>
                    <label className='labelStyle' >Routing number*</label>
                    <Field className='fields' type="text" placeholder='Routing number' name='routing_number' minLength={9} maxLength={9} />
                    <ErrorMessage name='routing_number' component='div' className='error' />

                  </div>
                  <div className='input-coll'>
                    <label className='labelStyle' >Bank Name</label>
                    <Field className='fields' type="text" placeholder='Bank name' name='bank_name' />
                    <ErrorMessage name='bank_name' component='div' className='error' />

                  </div>

                  <div className='input-coll'>
                    <label className='labelStyle'>Account Number*</label>
                    <Field className='fields' type='text' placeholder='Account Number' name='account_number' minLength={4} maxLength={17} />
                    <ErrorMessage name='account_number' component='div' className='error' />

                  </div>

                  <div className='input-coll'>
                    <label className='labelStyle' >Account Type</label>
                    <Field as="select" name="account_method" className='fields'>
                      <option value="">Select Bank Account Type</option>
                      <option value="8">Personal checking account</option>
                      <option value="9">Personal saving account</option>
                      <option value="10">Corporate checking account</option>
                      <option value="11">Corporate saving account</option>
                    </Field>
                    <ErrorMessage name='account_method' component='div' className='error' />

                  </div>

                  <div className="input-coll">
                    <Field type="text" name={`fax_four`} style={{ display: "none !important" }} hidden />
                  </div>
                </div>
                <div className='termsConditions'>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <input type="checkbox" id="agreeCheckbox" onClick={handleCheckbox} />
                    <label htmlFor="agreeCheckbox">I have read, understand and accept the <a href="">terms and conditions</a></label>
                  </div>
                </div>

                <div className='input-coll'>
                  <ReCAPTCHA
                    className="recaptcha-container"
                    sitekey="6Lc5yLcpAAAAAGmxkAx0XZEdkPNJ-NzQsA4XHuPs"
                    onChange={handleCaptchaChange}
                    ref={captchaReference}
                  />
                </div>


                <div className='Btn1div'>
                  <button type='button' onClick={() => setStep(step - 1)} className='PrevBtn'>Previous</button>
                  <button type="submit" className='FirstPgBtn '>Continue</button>
                </div>

              </Form>
            )}
          </Formik>
        </div>}
      {loading && (
        <div className="loader-container ">
          <>
            <div className="loader-background"></div> {/* Background blur */}
            <div className="loader-content">
              <Puff color="#ffffff" height={100} width={100} />
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default StepFour;

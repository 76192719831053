import React from 'react'

const Application = ({ setStep,step}) => {
    return (
        <div className=''>
            <div className="">
                <div className="text-center">
                    <img src="/owner-info.png" />
                    <h4>Application for Merchant Services Account</h4>
                    <span>Hey, we’re glad you’re here</span>
                </div>
                <div className="app-time-head">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 0C5.61553 0 4.26216 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.00303301 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C13.9978 5.14416 13.2596 3.36495 11.9473 2.05267C10.6351 0.74039 8.85585 0.00218939 7 0ZM7 12.7273C5.86725 12.7273 4.75995 12.3914 3.8181 11.7621C2.87626 11.1327 2.14218 10.2383 1.70869 9.19173C1.27521 8.14521 1.16179 6.99365 1.38278 5.88266C1.60377 4.77168 2.14924 3.75118 2.95021 2.95021C3.75118 2.14923 4.77169 1.60376 5.88267 1.38277C6.99365 1.16179 8.14521 1.27521 9.19173 1.70869C10.2383 2.14217 11.1327 2.87625 11.7621 3.8181C12.3914 4.75994 12.7273 5.86725 12.7273 7C12.7254 8.5184 12.1214 9.97408 11.0477 11.0477C9.97408 12.1214 8.5184 12.7254 7 12.7273Z" fill="#35254D" />
                        <path d="M7.63494 6.73672V3.182C7.63494 3.01322 7.5679 2.85136 7.44856 2.73202C7.32921 2.61268 7.16735 2.54563 6.99858 2.54563C6.8298 2.54563 6.66794 2.61268 6.5486 2.73202C6.42926 2.85136 6.36222 3.01322 6.36222 3.182V7.00018C6.36225 7.16894 6.42932 7.33077 6.54867 7.45009L8.45776 9.35918C8.57778 9.47509 8.73853 9.53924 8.90538 9.53779C9.07223 9.53634 9.23184 9.46941 9.34983 9.35142C9.46781 9.23344 9.53474 9.07383 9.53619 8.90698C9.53764 8.74012 9.4735 8.57938 9.35758 8.45936L7.63494 6.73672Z" fill="#35254D" />
                    </svg>
                    <p>This application can take 15-30 minutes to complete.</p>
                </div>
                <div className="application-info">
                    <span className="main-ton">We recommend having the following information on hand before you begin:</span>
                    <div className="disk">
                        <span className="pur-circle">1</span>
                        <span className="disk-det">Detailed information for each owner with more than 25% ownership, including their SSN, date of birth, and address.</span>
                    </div>
                    <div className="disk">
                        <span className="pur-circle">2</span>
                        <span className="disk-det">Detailed information about the company, including its EIN/Tax ID, address, and date of establishment.</span>
                    </div>
                    <div className="disk">
                        <span className="pur-circle">3</span>
                        <span className="disk-det">Processing volume amounts and types</span>
                    </div>
                    <div className="disk">
                        <span className="pur-circle">4</span>
                        <span className="disk-det">Banking information (where to submit funds)</span>
                    </div>
                </div>
                <button className='FirstPgBtn lets-go-btn' onClick={()=>setStep(step+1)}>Let’s go </button>
            </div>
        </div>
    )
}

export default Application
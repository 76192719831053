export const convertImagesToBase64 = (files) => {
    return Promise.all(
        files.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    resolve({
                        name: file.name.replaceAll(" ", "_"), // Include the file name
                        data: reader.result, // Base64 data
                    });
                };
                reader.onerror = () => {
                    reject("Error reading file");
                };
            });
        })
    );
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import reportWebVitals from './reportWebVitals';
import TransactionTable from './TransactionTable';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SuccessPage from './Success';
import TransactionField from './TransactionsField';

import SAMLAuth from './SAMLAuth';
import Auth from './Auth';
import AuthError from './AuthError';
import OnboardingMain from './onboarding/OnboardingMain';
import { ToastContainer } from 'react-toastify';
import AddOwners from './onboarding/AddOwners';
import 'react-responsive-modal/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import DigitalSignature from './digitalSignature/DigitalSignature';
import ViewOnboarding from './digitalSignature/ViewOnboarding';
import * as serviceWorker from './serviceWorker';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const Root = () => {
 
  //const root = ReactDOM.createRoot(document.getElementById('root'));

  return (
    <Router>
      <Routes>
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/transaction/:tid/:mid" element={<TransactionTable />} />
        <Route path="/clientransaction/:token" element={<TransactionField />} />

        <Route path="/saml-auth/onboarding/:clientkey/:merchant_id/:session_token?" element={<SAMLAuth  />} />
        <Route path="/auth" element={<Auth  />} />
        <Route path="/error-auth" element={<AuthError  />} />
        <Route path="/merchant-onboarding/:clientkey/:merchant_id" element={<OnboardingMain  />} />
        <Route path="/merchant-onboarding/:clientkey" element={<OnboardingMain />} />
        <Route path="/saml-auth/add-owner/:fractalpayPublicKey/:merchant_id" element={<AddOwners />} />
        <Route path="/digital-sign" element={<DigitalSignature />} />
        <Route path="/read-pdf/:public_key/:mfa_key" element={<ViewOnboarding />} />
        {/* <Route path="/read-pdf/:pdf_name/:sign_pdf_name/:public_key/:mfa_key" element={<ViewOnboarding />} /> */}
      
      
      </Routes>
      <ToastContainer />

    </Router>
  );
};

// root.render(
//   <Router>
//     <Routes>

//     <Route path="/merchant-onboarding/:authkey" element={<App />} />
//     <Route path="/success" element={<SuccessPage/>} />
//         <Route path="/merchant-onboarding" component={Table} />
//         <Route path="/transaction/:tid/:mid" element={<TransactionTable/>} />    
//         <Route path="/clientransaction/:token" element={<TransactionField/>} />
//     </Routes>
//   </Router>
// );

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
reportWebVitals();

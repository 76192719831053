import React, { useEffect, useState } from "react";
import axios from 'axios';
import {useParams} from 'react-router-dom'
const baseUrl = process.env.REACT_APP_API_BASE_URL;


const TransactionTable = () => {
  const { tid , mid} = useParams(); 

    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
          
          try {
          
            const response = await axios.post(
              `${baseUrl}/api/v1/merchant/get-client-transaction/${tid}`,
              {
                "merchant_key":`${mid}` ,
                "sub_merchant_key": "",
                // "from_date": "2024-03-15",
                // "to_date": "2024-03-15",
                "pageNumber": 0,
                "limit": "20",
                "transctionId":""
            },
              {
                headers: {
                  // 'Authorization': 'Basic YWY2NTU5M2MtZTEyYS0xMWVlLWI3ZmEtMDJmYWJkODkwNTAwOjEwYzg4NmMxMmI2ZDBhOTM1MTEzYmE2NjE3MTVjNzJjNjkyZDZhMDJiYmZlOTI1NjZmc2RmZmZmNjRmZA==',
                  'Content-Type': 'application/json'
                }
              }
            );
            setTransactions(response.data.data);
            setLoading(false);
          } catch (error) {
            console.error('Error:', error);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);
      if (loading) {
        return <div>Loading...</div>;
      }
    return (
        <>
            <h3>Transactions</h3>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Customer name</th>
                        <th>Amount</th>
                        <th> Net Amount</th>
                        <th>Invoice</th>
                    </tr>
                </thead>
                <tbody>
          {transactions.map(transaction => (
            <tr key={transaction.id}>
              <td>{transaction.transaction_date}</td>
              <td>{transaction.cutomer_name}</td>
              <td>{transaction.amount}</td>
              <td>{transaction.net_amount}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
            </table>

        </>
    )
}
export default TransactionTable;
import React, { useState } from 'react'
import "./Auth.css";
import logo from "./assests/logo192.png";
import dispatch from "./assests/dispatch.png";
import axios from 'axios';
import { Puff } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;
const Auth = () => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const fetchData = async (session_token) => {
        try {
            setLoading(true)
            const response = await axios.post(
                `${apiUrl}/saml_session_detail`,
                {
                    session_token: session_token,
                    fractalpayPublicKey: "0a536b8f8cdba20c6bef714cc7389ff8",
                }
            );
            setLoading(false)
            if (response?.data?.result) {
                let clientDetails = response?.data?.data?.profile;
                navigate(`/merchant-onboarding/0a536b8f8cdba20c6bef714cc7389ff8`, { state: { clientDetails } });
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
            // toast.error(error?.message)
        }
    };

    const login = async () => {
        try {
            setLoading(true)
            const response = await axios.get(
                // `${apiUrl}/api/v1/merchant/saml_auth`,
                `${apiUrl}/api/v1/merchant/saml_auth`,
                {
                    headers: {
                        'Authorization': `Basic YWY2NTU5M2MtZTEyYS0xMWVlLWI3ZmEtMDJmYWJkODkwNTAwOjEwYzg4NmMxMmI2ZDBhOTM1MTEzYmE2NjE3MTVjNzJjNjkyZDZhMDJiYmZlOTI1NjZmc2RmZmZmNjRmZA==`,
                    }
                }
            );
            setLoading(false)
            if (response?.data?.result) {
                let token = response?.data?.data?.sessionToken;
                fetchData(token);
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
            // toast.error(error?.message)
        }
    };

    return (
        <>
            {loading && (
                <>
                    <div className="loader-background"></div> {/* Background blur */}
                    <div className="loader-content">
                        <Puff color="#ffffff" height={100} width={100} />
                    </div>
                </>
            )}

            <div className="card integration">
                <div className='d-flex justify-content-between'>
                    <div className="card-title d-flex">
                        <img src={logo} className="card-icon" />
                        <div>
                            <strong>Fractal</strong>
                            <div className='not-start'>
                                <svg width="18" height="18" viewBox="0 0 836 836" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="417.948" cy="417.834" r="408.5" transform="rotate(1.32457 417.948 417.834)" fill="#939094" />
                                    <g clipPath="url(#clip0_792_96)">
                                        <path d="M417 662C352.022 662 289.705 636.188 243.759 590.241C197.812 544.295 172 481.978 172 417C172 352.022 197.812 289.705 243.759 243.759C289.705 197.812 352.022 172 417 172C481.978 172 544.295 197.812 590.241 243.759C636.188 289.705 662 352.022 662 417C662 481.978 636.188 544.295 590.241 590.241C544.295 636.188 481.978 662 417 662ZM417 697C491.261 697 562.48 667.5 614.99 614.99C667.5 562.48 697 491.261 697 417C697 342.739 667.5 271.52 614.99 219.01C562.48 166.5 491.261 137 417 137C342.739 137 271.52 166.5 219.01 219.01C166.5 271.52 137 342.739 137 417C137 491.261 166.5 562.48 219.01 614.99C271.52 667.5 342.739 697 417 697Z" fill="white" />
                                        <path d="M422.285 313.925C425.148 312.451 428.361 311.795 431.572 312.03C434.783 312.265 437.867 313.381 440.485 315.255L562.985 402.755C565.254 404.374 567.103 406.511 568.379 408.989C569.655 411.467 570.32 414.213 570.32 417C570.32 419.787 569.655 422.534 568.379 425.011C567.103 427.489 565.254 429.626 562.985 431.245L440.485 518.745C437.868 520.618 434.786 521.733 431.576 521.968C428.367 522.202 425.155 521.547 422.293 520.075C419.432 518.603 417.032 516.37 415.357 513.622C413.682 510.874 412.798 507.718 412.8 504.5V329.5C412.797 326.283 413.68 323.127 415.354 320.379C417.027 317.632 419.425 315.398 422.285 313.925Z" fill="white" />
                                        <rect x="364.5" y="313.4" width="30.1" height="208.6" rx="15.05" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_792_96">
                                            <rect width="560" height="560" fill="white" transform="translate(137 137)" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                {/* <span className='status'>{fractalStatus?.result ? 'pending' : 'Not Started'}</span> */}
                                <span className='status'>Not Started</span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="card-subtitle dropdown">
                    <button onClick={toggleDropdown} className="dropdown-toggle">
                        <svg width="6" height="18" viewBox="0 0 6 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.875 2.75C4.875 2.25272 4.67746 1.77581 4.32583 1.42417C3.97419 1.07254 3.49728 0.875 3 0.875C2.50272 0.875 2.02581 1.07254 1.67417 1.42417C1.32254 1.77581 1.125 2.25272 1.125 2.75C1.125 3.24728 1.32254 3.72419 1.67417 4.07583C2.02581 4.42746 2.50272 4.625 3 4.625C3.49728 4.625 3.97419 4.42746 4.32583 4.07583C4.67746 3.72419 4.875 3.24728 4.875 2.75ZM4.875 9C4.875 8.50272 4.67746 8.02581 4.32583 7.67417C3.97419 7.32254 3.49728 7.125 3 7.125C2.50272 7.125 2.02581 7.32254 1.67417 7.67417C1.32254 8.02581 1.125 8.50272 1.125 9C1.125 9.49728 1.32254 9.97419 1.67417 10.3258C2.02581 10.6775 2.50272 10.875 3 10.875C3.49728 10.875 3.97419 10.6775 4.32583 10.3258C4.67746 9.97419 4.875 9.49728 4.875 9ZM4.875 15.25C4.875 14.7527 4.67746 14.2758 4.32583 13.9242C3.97419 13.5725 3.49728 13.375 3 13.375C2.50272 13.375 2.02581 13.5725 1.67417 13.9242C1.32254 14.2758 1.125 14.7527 1.125 15.25C1.125 15.7473 1.32254 16.2242 1.67417 16.5758C2.02581 16.9275 2.50272 17.125 3 17.125C3.49728 17.125 3.97419 16.9275 4.32583 16.5758C4.67746 16.2242 4.875 15.7473 4.875 15.25Z" fill="#79747E" />
                        </svg>

                    </button>
                    {isOpen && (
                        <ul className="dropdown-menu">
                            <li className="dropdown-item" onClick={toggleDropdown}>Remove</li>
                        </ul>
                    )}
                </div> */}
                </div>
                <div className="card-content">
                    Version 2.4.8
                </div>
                <hr />
                <div className='row d-flex justify-content-between align-items-center'>
                    <div className='dispatch'><img src={dispatch} alt="dispatch" /></div>
                    <div className='start-btn'><button onClick={login}>Start</button></div>
                    {/* <div className='start-btn'>{!fractalStatus?.result && <button onClick={() => setShow(true)}>Start</button>}</div> */}
                </div>
                {/* <Modal show={show} onHide={() => setShow(false)} centered>
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                        <div className='text-center mdl-sec'>
                            <h4>Allow Command Alkon to access your Fractal account?</h4>
                            <img src={logo} className="card-icon" />
                            <div className='d-flex justify-content-between pt-5'>
                                <button className='start' style={{ marginRight: '10px' }} onClick={() => setShow(false)}>Cancel</button>
                                <button className='start' onClick={login}>Yes, continue to Fractal</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal> */}
            </div>
        </>
    )
}

export default Auth

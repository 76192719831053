import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Puff } from 'react-loader-spinner';

const apiUrl = process.env.REACT_APP_API_URL;
const hostUrl = process.env.REACT_APP_HOST_URL;
const SAMLAuth = () => {
    const [loading, setLoading] = useState(false);
    const { clientkey, merchant_id, session_token } = useParams();
    // const [dataList, setDataList] = useState({});
    const navigate = useNavigate();
    // const fetchData = async () => {
    //     try {
    //         setLoading(true)
    //         const response = await axios.post(
    //             `${apiUrl}/saml_session_detail`,
    //             {
    //                 session_token: session_token,
    //                 fractalpayPublicKey: clientkey,
    //             }
    //         );
    //         setLoading(false)
    //         if (response?.data?.result) {
    //             let clientDetails = response?.data?.data?.profile;
    //             console.log("api response ========>", response?.data?.data);
    //             navigate(`/merchant-onboarding/${clientkey}`, { state: { clientDetails } });
    //             // setDataList(clientDetails);
    //         }

    //     } catch (error) {
    //         setLoading(false)
    //         console.log(error);
    //         // toast.error(error?.message)
    //     }
    // };
    const setLoalData = (data) => {
        let details = {
            ownership_detail: [...data?.owners],
            business_name: data?.business_name ? data?.business_name : '',
            business_address: data?.business_address ? data?.business_address : '',
            ownershiptype: data?.ownershiptype ? data?.ownershiptype : '',
            business_structure: data?.business_structure ? data?.business_structure : '',
            business_state: data?.business_state ? data?.business_state : '',
            business_city: data?.business_city ? data?.business_city : '',
            business_zip: data?.business_zip ? data?.business_zip : '',
            business_country: data?.business_country ? data?.business_country : '',
            ein: data?.ein ? data?.ein : '',
            website: data?.website ? data?.website : '',
            established_date: data?.established_date ? data?.established_date : '',
            business_industry: data?.business_industry ? data?.business_industry : '',
            legal_productssold: data?.legal_productssold ? data?.legal_productssold : '',
            processing_environment: data?.processing_environment ? data?.processing_environment : '',
            avgTicket: data?.avgTicket ? data?.avgTicket : '',
            annualCCSales: data?.annualCCSales ? data?.annualCCSales : '',
            legal_b2c: data?.legal_b2c ? data?.legal_b2c : '',
            legal_b2b: data?.legal_b2b ? data?.legal_b2b : '',
            legal_cnpinternet: data?.legal_cnpinternet ? data?.legal_cnpinternet : '',
            legal_cnpphoneemail: data?.legal_cnpphoneemail ? data?.legal_cnpphoneemail : '',
            legal_percentdeliveredover30days: data?.legal_percentdeliveredover30days ? data?.legal_percentdeliveredover30days : '',
            legal_percentdeliveredsameday: data?.legal_percentdeliveredsameday ? data?.legal_percentdeliveredsameday : '',
            percentdelievered7days: data?.percentdelievered7days ? data?.percentdelievered7days : '',
            percentdelivered14days: data?.percentdelivered14days ? data?.percentdelivered14days : '',
            legal_cp: data?.legal_cp ? data?.legal_cp : '',
            legal_keyed: data?.legal_keyed ? data?.legal_keyed : '',
        }

        let localDetails = JSON.stringify(details);
        localStorage.setItem('client-details', localDetails);
    }
    const fetchData = async () => {
        try {
            setLoading(true)
            const response = await axios.post(
                `${apiUrl}/saml_session_detail/${session_token}`,
                {
                    fractalpayPublicKey: clientkey,
                    merchant_id,
                }
            );
            setLoading(false)
            if (response?.data?.result) {
                setLoalData(response?.data?.data)
                let owners = [...response?.data?.data?.owners]
                let clientDetails = { ...response?.data?.data, ownership_detail: owners };
                // delete clientDetails.owners;
                // console.log("api response ========>", response?.data?.data);
                // let localDetails = JSON.stringify(clientDetails);
                // localStorage.setItem('client-details', localDetails);
                navigate(`/merchant-onboarding/${clientkey}/${merchant_id}`, { state: { clientDetails } });
                // setDataList(clientDetails);              
            }

        } catch (error) {
            setLoading(false)
            navigate(`/error-auth`);
            console.log(error.response.data);
            // toast.error(error?.message)
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    return loading && (
        <>
            <div className="loader-background"></div> {/* Background blur */}
            <div className="loader-content">
                <Puff color="#ffffff" height={100} width={100} />
            </div>
        </>
    )
}

export default SAMLAuth;

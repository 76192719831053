import { ErrorMessage } from 'formik';
import React from 'react'
import { NumericFormat } from 'react-number-format';

const AvgNumberformt = ({ field, setFieldValue, values, name, labelName }) => {

    return (

        <div className='input-coll'>
            <label className='labelStyle' >{labelName}</label>
            <NumericFormat
                className="fields"
                prefix="$"
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator=","
                allowNegative={false}
                {...field}
                value={values[name]}
                placeholder={labelName}
                onValueChange={(data) => {
                    const { value } = data;
                    setFieldValue(name, value);
                }}
            />
            <ErrorMessage name={name} component='div' className='error' />
        </div>
    )
}

export default AvgNumberformt
import axios from 'axios';
import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Puff } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const DigitalSignature = ({ setSignatureLoading, setSuccess, setIsSigned }) => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const sigCanvas = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    const clear = () => {
        sigCanvas.current.clear();
    };

    const save = async () => {
        try {
            if (!sigCanvas.current.isEmpty()) {
                let sign = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png", 1.0);
                let data = {
                    signature: sign,
                    request_id: params?.mfa_key,
                    fractalpayPublicKey: params?.public_key
                   
                };
                setSignatureLoading(true);
                setLoading(true);
                const response = await axios.post(`${apiUrl}/upload/signature`, data);
                if(response?.status){
                    setSuccess(true);
                }
                // let name = response?.data?.data?.updatedFile?.split('/')[3];
                // navigate(`/read-pdf/${name}/${params?.public_key}/${params?.mfa_key}`, { replace: true });

                // Trigger success callback after signature upload
                // onSuccess();

            } else {
                throw new Error("Signature Required");
            }
            setSignatureLoading(false);
            setLoading(false);
        } catch (error) {
            setSignatureLoading(false);
            setLoading(false);
            toast.error(error?.response?.data?.message);
        }
    };

    return (
        <div className='digi-sign'>
            <h2 style={{ textAlign: "center" }}>Digital Signature</h2>
            <p>By adding my signature, I acknowledge that I have read and understood the documents.</p>
            <SignatureCanvas ref={sigCanvas} penColor="black" canvasProps={{ className: 'sigCanvas' }} backgroundColor="rgba(255, 255, 255, 0)" />
            <div style={{ textAlign: "center", marginTop: '10px' }}>
                <button onClick={clear}>Clear</button>
                <button onClick={save} disabled={loading}>Save</button>
            </div>
        </div>
    );
};

export default DigitalSignature;



            {/* {trimmedDataURL && (
                <div style={{ marginTop: '10px' }}>
                    <h3>Base64</h3>
                    <textarea
                        value={trimmedDataURL}
                        readOnly
                        rows="5"
                        cols="80"
                        style={{ marginTop: '10px', width: '100%' }}
                    />
                </div>
            )} */}


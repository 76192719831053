import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from 'react-router-dom'





const baseUrl = process.env.REACT_APP_API_BASE_URL;   
const TransactionField = () => {
    const { token } = useParams();
    const [searchQuery, setSearchQuery] = useState('');

    const [transactions, setTransactions] = useState([]);
    const [businessName, setBusinessName] = useState('');
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState('');
    
    const [endDate, setEndDate] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            try {
                axios({
                    method: "get",
                    url: `${baseUrl}/api/v1/client/merchant-transactions`,
                    params: {
                        "from_date": startDate,
                        "to_date": endDate,
                        "pageNumber": 0,
                        "limit": "20",
                        "search": searchQuery
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                }).then(function (response) {
                    setBusinessName(response.data.data.business_name);
                    setTransactions(response.data.data.transactions);
                  

                    setLoading(false);
                }).catch(function (err) { console.error("Error!") });

            } catch (error) {
                console.error('Error:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [startDate, endDate, searchQuery]);

    if (loading) {
        return <div>Loading...</div>;
    }

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const filteredTransactions = transactions.filter(transaction => {
    
        return (
            Object.values(transaction).some(value =>
                typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
            ) &&
            (!startDate || transaction.transaction_date >= startDate) &&
            (!endDate || transaction.transaction_date <= endDate)
        );
    });
    

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    };
    const formatCurrency = (value, currency = 'USD', locale = 'en-US') => {
        return new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: currency,
        }).format(value);
      };

    return (
        <>
           <div className="filters-wrapper">
    <div className="transaction-field-container">
        <div className="transaction-heading" >
        <h3>{businessName}</h3>

        </div>
        <div className="filters-container">
            <div className="date-filters">
            <div>
                <input type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
            </div>
                <div>
                    <label>From:</label>
                    <input type="date" value={startDate} onChange={handleStartDateChange} />
                </div>
                <div>
                    <label>To:</label>
                    <input type="date" value={endDate} onChange={handleEndDateChange} />
                </div>
            </div>
          
        </div>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Customer name</th>
                            <th>Amount</th>
                            <th> Net Amount</th>
                            {/* <th>Invoice</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTransactions.length === 0 ? (
                            <tr>
                                <td colSpan="5">No data available</td>
                            </tr>
                        ) : (
                            filteredTransactions.map(transaction => (
                                <tr key={transaction.id}>
                                    <td>{formatDate(transaction.transaction_date)}</td>
                                    <td>{transaction.cutomer_name}</td>
                                    <td>{formatCurrency(transaction.amount)}</td> 
                                    <td>{formatCurrency(transaction.net_amount)}</td> 
                                    {/* <td></td> */}
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                {/* <div className="pagination-container">
        <Pagination count={10} shape="rounded" />
      </div> */}
    
            </div>
            </div>
        </>
    )
}
export default TransactionField;
import React, { useEffect, useState } from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { PDFDocument } from 'pdf-lib';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { useParams } from 'react-router-dom';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import axios from 'axios';
import { toast } from 'react-toastify';
const pdfjsDist = require('pdfjs-dist/package.json');
const BeforeSignedFile = ({ setIsSigned, setLoading, isSigned, success, }) => {

    const params = useParams();
    const [mergedPdfUrl, setMergedPdfUrl] = useState(null);
    const zoomPluginInstance = zoomPlugin();
    const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;
    const fullScreenPluginInstance = fullScreenPlugin();
    const { EnterFullScreenButton } = fullScreenPluginInstance;
    const getBase64Strings= async()=>{
        try {
            setLoading(true);
            const data={
                mfa_id: params?.mfa_key,
                fractalpayPublicKey: params?.public_key,
            };
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/getpdf`,data);
            if(response?.status){
                setIsSigned((response?.data?.data?.encoded_sign_pdf && response?.data?.data?.encoded_guide_signed_program_pdf) ? true:false);
                let str1 = response?.data?.data?.encoded_sign_pdf ? response?.data?.data?.encoded_sign_pdf : response?.data?.data?.encoded_mfa_pdf
                let str2 = response?.data?.data?.encoded_guide_signed_program_pdf ? response?.data?.data?.encoded_guide_signed_program_pdf : response?.data?.data?.encoded_guided_pdf
                mergePdfs(str1, str2);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error(error?.response?.data?.message);
        }
    }

    const mergePdfs = async (string1,string2) => {
        try {
            // Assume you get these base64 strings from some API or source
            const base64Pdf1 = string1;
            const base64Pdf2 = string2;

            // Decode base64 to ArrayBuffer
            const decodeBase64 = (base64) => {
                const binaryString = window.atob(base64);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                return bytes.buffer;
            };

            const pdf1 = decodeBase64(base64Pdf1);
            const pdf2 = decodeBase64(base64Pdf2);

            // Load both PDFs
            const pdfDoc1 = await PDFDocument.load(pdf1, { ignoreEncryption: true });
            const pdfDoc2 = await PDFDocument.load(pdf2, { ignoreEncryption: true });

            // Create a new PDFDocument
            const mergedPdfDoc = await PDFDocument.create();

            // Copy all pages from pdfDoc1 and pdfDoc2
            const pages1 = await mergedPdfDoc.copyPages(pdfDoc1, pdfDoc1.getPageIndices());
            pages1.forEach((page) => mergedPdfDoc.addPage(page));

            const pages2 = await mergedPdfDoc.copyPages(pdfDoc2, pdfDoc2.getPageIndices());
            pages2.forEach((page) => mergedPdfDoc.addPage(page));

            // Serialize the merged PDF document to bytes
            const mergedPdfBytes = await mergedPdfDoc.save();

            // Convert the merged PDF bytes to a Blob and create an object URL
            const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
            const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);

            // Set merged PDF URL to state
            setMergedPdfUrl(mergedPdfUrl);
        } catch (error) {
            console.error('Error merging PDFs:', error.message);
        }
    };
    useEffect(() => {
        getBase64Strings();     
        
    }, [success]);

    return (
        <>
          
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsDist.version}/build/pdf.worker.min.js`}>
                <div className='file-viewer'  >
                    {/* Zoom and Full Screen Controls */}
                    <div className='controls'>
                        <ZoomOut>
                            {(props) => (
                                <button className='zoom' onClick={props.onClick}>
                                    -
                                </button>
                            )}
                        </ZoomOut>
                        <CurrentScale>
                            {(props) => (
                                <span style={{ margin: '0 8px', color: '#121212' }}>
                                    {`${Math.round(props.scale * 100)}%`}
                                </span>
                            )}
                        </CurrentScale>
                        <ZoomIn>
                            {(props) => (
                                <button className='zoom' onClick={props.onClick}>
                                    +
                                </button>
                            )}
                        </ZoomIn>
                        <span className='full-screen'>
                            <EnterFullScreenButton />
                        </span>
                    </div>

                    {/* PDF Viewer */}
                    {mergedPdfUrl && (
                        <Viewer fileUrl={mergedPdfUrl} plugins={[zoomPluginInstance, fullScreenPluginInstance]} />
                    )}
                </div>
            </Worker>
        </>
    );
};

export default BeforeSignedFile;

import React from 'react';
// import { useLocation } from 'react-router-dom';

const AuthError = () => {
  // const location=useLocation();
  
  return (
      <div className='row'>
        <div className="container all-steps final ">
          <div className='succ-regi'>
            <div className='text'>
              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="150" viewBox="0 0 24 24">
                <path fill="currentColor" d="m12 1l11.951 20.7H.05L12 1ZM3.513 19.7h16.974L12 5L3.513 19.7ZM13 9.5V15h-2V9.5h2Zm-2 7h2.004v2.004H11V16.5Z" />
              </svg>
              <h1>Oops</h1>
              <p>Your session has expired.</p>
            </div>
          </div>
        </div>
      </div>
  );
}



export default AuthError